.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #0D1821;
}

.text-center{
  text-align: center;
}

.bold {
  font-weight: bold;
}

body {
  background-color: #F0F4EF;
  font-family: 'Barlow', sans-serif;
}

label {
  display: block;
  font-size: 0.7em;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 2px;
}

.input-dark {
  all: unset;
  border: none;
  border-bottom: 1px solid #F0F4EF;
  border-radius: 2px;
  padding: 1px;
  width: 150px;
  height: 20px;
  margin-bottom: 5px;
  color: #F0F4EF;
  transition: 0.2s;
}

.input-dark:focus {
  border-bottom: 3px solid #F0F4EF;
  background-color: #36454F;
}

input {
  all: unset;
  border: none;
  border-bottom: 1px solid #0D1821;
  border-radius: 2px;
  padding: 1px;
  width: 150px;
  height: 20px;
  margin-bottom: 5px;
  color: #222;
  transition: 0.2s;
}

input:focus{
  border-bottom: 3px solid #0D1821;
  background-color: #f0f0f0;
}

a {
  text-decoration: none;
}

.dark-link{
  color: white;
}

.italic {
  font-family: 'Kalam', sans-serif;
}

textarea {
  all: unset;
  border: none;
  width: 100%;
  border-bottom: 3px solid #0D1821;
  border-top: 1px solid #0D1821;
  border-radius: 2px;
  font-family: 'Kalam', sans-serif;
}

select {
  border: none;
  border-bottom: 1px solid rgb(59, 0, 3);
  border-radius: 2px;
  background-color: #F0F4EF;
  width: 160px;
  padding: 4px;
  margin-bottom: 14px;
}

.w-180{
  width: 180px;
}

select:focus{
  outline: none;
}

table {
  border-collapse: collapse;
}

.danger {
  color:rgb(158, 0, 8);
}

.danger-dark {
  color:rgb(247, 1, 13)
}

.event-button-container {
  margin-top: 30px;
  margin-bottom: 30px;
}

.control-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 5px;
}


.btn {
  border: none;
  outline: none;
  color: #F0F4EF;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: normal;
  border-radius: 8px;
  transition: 1s;
  width: 100px;
  height: 60px;
  padding: 5px;
  font-size: 1em;
}

.btn:hover {
  cursor: pointer;
}

.btn-large {
  width: 180px;
}

.btn-small {
  width: 82px;
  height: 36px;
}

.pullup {
  margin-top: -12px;
}

.btn-xl:hover {
  background-color: #0D1821;
}

.btn-xl {
  width: 250px;
  height: 100px;
  border-radius: 15px;
  font-size: 1.2em;
  transition: 0.2s;
}

.btn-secondary {
  background-color: #0D1821;
}

.btn-secondary:hover {
  background-color:rgb(56, 56, 56)
}

.btn-tertiary {
  background-color: #F0F4EF;
  border: 1px solid #0D1821;
  color: rgb(44, 44, 44);
}

.btn-tertiary:hover {
  border: 2px solid rgb(44,44,44);
  background-color: white;
}

.btn-primary {
  background-color:#344966;
}

.btn-event {
  background-color: #0D1821;
  font-weight: bolder;
  font-size: 1.2em;
  height: 150px;
  /* width: 100px; */
  width: 150px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 50%;
  /* border-radius: 10px; */
  padding: 5px;
}

.btn-event:hover {
  cursor: pointer;
  background-color:rgb(56, 56, 56)
}



.btn-danger{
  background-color:rgb(158, 0, 8);
}

.btn-danger:hover{
  background-color:rgb(192, 0, 10)
}

.btn-primary:hover {
  background-color:#0D1821;
}

.margin-auto {
  margin: auto
}


.timer {
  border-top: 5px solid #0D1821;
  /* border-bottom: 1px solid rgb(44,44,44); */
  /* position: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  border-bottom: none;
  border-radius: 50%;
  text-align: center;
  font-size: xx-large;
  max-width: 90%;
  width: 200px;
  height: 200px;
  margin: auto;
  margin-bottom: -75px;
  /* background-color: rgb(167, 167, 167); */
}

.bold {
  font-weight: bold;
}

.register-baby-header {
  font-size: 1.4em;
  font-weight: 500;
  margin-bottom: 30px;
}


.register-baby-input {
  margin-bottom: 15px;
}

.register-baby-input-suffix {
  font-size: 0.8em;
  margin-left: 5px;
}

.border-bottom {
  border-bottom: 1px solid rgb(44, 44, 44);
}

.border-right {
  border-right: 1px solid rgb(44,44,44);
}

.minw-100p{
  min-width: 100px;
}

.w-200p {
  width: 200px;
}

.w-15 {
  width: 15%;
}

.w-10 {
  width: 10%;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.text-left {
  text-align: left;
}

.text-center{
  text-align: center;
}

.center {
  margin: auto;
}

.hover-pointer{
  cursor: pointer;
}

.dark-landing{
  background-color: #36454F;
  height: 100vh;
  color: rgb(233, 232, 232);
}

.page {
  padding: 20px;
  padding-top: 40px;
  margin-bottom: 50px;
}

.card {
  background-color: #fff;
  margin: 2px;
  padding: 15px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 30px;
}

.card-header {
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
}

.bg-french-gray {
  background-color: #BFB6BB;
}

.bg-onyx {
  background-color: #36393B;
}

.bg-sage {
  background-color: #BFCC94;
}

.bg-light-blue {
  background-color: #B4CDED;
}

.bg-ash-gray {
  background-color: #8E9B90;
}

.bg-cambridge-blue {
  background-color: #93C0A4;
}

.bg-dutch-white {
  background-color: #D4CDAB;
}

.bg-beige {
  background-color: #DCE2BD;
}

.bg-primary-blue {
  background-color: #344966;
}

.bg-dark-blue {
  background-color: #0D1821;
}

.content-card {
  background-color: #fff;
  border-radius: 30px;
  margin: 2px;
  padding: 0px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  padding-bottom: 18px;
  min-width: 280px;
  min-height: 160px;
}

.content-card-header {
  /* text-align: center; */
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
  border-radius: 30px 30px 0px 0px;
  padding: 14px;
  margin-bottom: 32px;
}

.content-card-header-text {
  margin-top: 0px;
  margin-left: 14px;
}

.content-card-header-icon {
  display: inline;
  text-align: center;
}

.content-card-add-btn {
  position: absolute;
  right: 24px;
  top: -78px;
}

.content-card-body{
  position: relative;
  text-align: center;
}

.inline-block {
  display: inline-block;
}

.navbar-button {
  background-color: #0D1821;
  float: left;
  display: block;
  color: #F0F4EF;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  outline: none;
  border: none;
}

.btn-dark {
  background-color:rgb(185, 185, 185);
  border-radius: 5px;
  display: block;
  color:rgb(44, 44, 44);
  text-decoration: none;
  padding: 8px 16px;
  outline: none;
  border: none;
  transition: 0.3s;
  cursor: pointer;
}

.btn-dark:hover{
  background-color: rgb(231, 229, 229);
}

#logout {
  float: right
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.ph-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.ph-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.add-button {
  position: relative;
  border-width: 2px;
  border-style: solid;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s all;
  background: #344966;
  border-color: #344966;
}
.add-button:before{
    border-color: #FFF;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-left-width: 3px;
    border-left-style: solid;
    left: 13px;
    top: 7px;
    content: "";
    position: absolute;
    height: 6px;
    width: 6px;
}

.add-button:after{
    border-color: #FFF;
    content: "";
    position: absolute;
    height: 6px;
    width: 6px;
    border-top-width: 3px;
    border-top-style: solid;
    border-right-width: 3px;
    border-right-style: solid;
    left: 7px;
    top: 13px;
}

.add-button:hover{
  background-color: #006db1;
  border-color: #006db1;
}

.add-button-lg {
  position: relative;
  border-width: 2px;
  border-style: solid;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s all;
  background: #344966;
  border-color: #344966;
}
.add-button-lg:before{
    border-color: #FFF;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-left-width: 3px;
    border-left-style: solid;
    left: 13px;
    top: 7px;
    content: "";
    position: absolute;
    height: 6px;
    width: 6px;
}

.add-button-lg:after{
    border-color: #FFF;
    content: "";
    position: absolute;
    height: 6px;
    width: 6px;
    border-top-width: 3px;
    border-top-style: solid;
    border-right-width: 3px;
    border-right-style: solid;
    left: 7px;
    top: 13px;
}

.add-button-lg:hover{
  background-color: #006db1;
  border-color: #006db1;
}
.ml-30{
  margin-left: 30px;
}
.ml-24{
  margin-left: 24px;
}
.ml-50{
  margin-left: 50px;
}
.ml-40{
  margin-left: 40px;
}

.ml-10 {
  margin-left: 10px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-30 {
  margin-bottom: 30px;
}
.mb-14 {
  margin-bottom: 14px;
}

.mr-30{
  margin-right: 30px;
}

.baby-card-hdr {
  padding: 2px;
  padding-right: 5px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-30{
  margin-top: 30px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-10{
  margin-top: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-10p{
  margin: 10%;
}

.pl-10 {
  padding-left: 10px;
}

.p-2{
  padding: 2px;
}

.p-4{
  padding: 4px;
}

.p-6{
  padding: 6px;
}

.p-10{
  padding: 10px;
}

.divided-tr {
  border-bottom: 2px solid #333;
}

.width-full{
  width: 100%;
}

.width-half {
  width: 50%
}

.w-175 {
  width: 175px;
}

.edit-button {
  float: right;
  border: none;
  font-weight: bold;
  outline: none;
  background: white;
  color: black;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  transition: 0.5s;
}

.flex {
  display: flex;
}

.flex-grow {
  flex: 1 1 0px;
}

.grid-container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}

@media (min-width: 35em) {
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}


.flex-wrap {
  flex-wrap: wrap;
}

.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.align-baseline{
  align-items: baseline;
}

.align-stretch {
  align-items: stretch;
}

.align-self-end {
  align-items: self-end;
}

.align-start {
  align-items: flex-start;
}

.space-between{
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.flex-start {
  justify-content: flex-start;
}

.flex-col {
  flex-direction: column;
}

.baby-overview-hdr {
  /* text-align: center; */
  margin-left: 32px;
  font-weight: bold;
  margin-bottom: 24px;
  margin-top: -10px;
  font-size: 1.8em;
}

.baby-summary-table {
  /* width: 40%; */
  /* border-right: 2px solid #00426eec; */
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.baby-hdr-txt {
  margin-left: 12px;
}

.user-profile {
  background-color: #93C0A4;
  padding: 8px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  padding-top: 20px;
  padding-left: 14px;
  padding-right: 14px;
  display: inline-block;
  text-align: center;
  vertical-align: baseline;
}

.user-profile-img{
  display: inline-block;
  height: 75px;
  width: 75px;
  border-radius: 50%;
  margin: 8px;
  margin-top: 20px;
  margin-left: 14px;
  margin-right: 14px;
  text-align: center;
  vertical-align: baseline;
}

.baby-summary-table th{
  padding-left: 10px;
}

.baby-summary-table td{
  text-align: right;
  padding-right: 10px;
  padding-bottom: 2px;
}

.p-5{
  padding: 5px
}

.border-top {
  border-top: 1px solid rgb(44,44,44);
}

.border-blue {
  border-color: rgb(220, 231, 250);
}

.table-header {
  font-size: x-large;
  font-weight: bolder;
  color: black; 
  border-top: 1px solid #00426eec;
  /* border-top-left-radius: 4px; */
}

.edit-button:hover {
  background: #ececec;
}

.text-sm {
  font-size: small;
}

.text-xs {
  font-size: x-small;
}

.text-lg {
  font-size: large;
}

.baby-container {
  width: 75%;
  display: flex;
}

.delete-button {
  border: none;
  font-weight: bold;
  outline: none;
  position: realtive;
  background: white;
  color: red;
  top: 7px;
  left:7px;
  cursor: pointer;
  padding: 3px;
  border-radius: 5px;
  transition: 0.5s;
}

.delete-button:hover {
  background: #ececec;
}

.black {
  color: black;
}

.red {
  color: rgb(179, 1, 1);
}

.amber {
  color: rgb(218, 143, 30);
}

.x {
    border: none;
    /* border-radius: 3px;
    border-color: black;
    border: 2px solid; */
    font-weight: bold;
    outline: none;
    position: absolute;
    background: white;
    color: black;
    top: 7px;
    left: 7px;
    cursor: pointer;
    transition: .5s;
}

.x:hover{
  background: #F0F4EF;
}

/* Change the link color to #111 (black) on hover */
.navbar-button:hover {
  background-color: #111;
}

.styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 280px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table caption {
  padding: 12px 15px;
  font-size: 1em;
  background-color: #f3f3f3;
}

.styled-table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader-dark {
  color: rgb(44, 44, 44);
  font-size: 12px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  /* margin: 72px auto; */
  margin: 5px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

.loader {
  color: white;
  font-size: 12px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  /* margin: 72px auto; */
  margin: 5px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Customize the label (the container) */
.checkmark-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


/* Hide the browser's default checkbox */
.checkmark-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #F0F4EF;
  border:1px solid rgb(44, 44, 44);
}

/* On mouse-over, add a grey background color */
.checkmark-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkmark-container input:checked ~ .checkmark {
  background-color: rgb(44, 44, 44);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkmark-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkmark-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkmark-container-sm {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkmark-container-sm input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-sm {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #F0F4EF;
  border:1px solid rgb(44, 44, 44);
}

/* On mouse-over, add a grey background color */
.checkmark-container-sm:hover input ~ .checkmark-sm {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkmark-container-sm input:checked ~ .checkmark-sm {
  background-color: rgb(44, 44, 44);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-sm:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkmark-container-sm input:checked ~ .checkmark-sm:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkmark-container-sm .checkmark-sm:after {
  left: 5px;
  top: 4px;
  width: 2px;
  height: 4px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}